import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import VideoDetail from "../../components/VideoDetail"
import FeatureLine from "../../components/FeatureLine"
// import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import ControlPanel from "../../components/ControlPanel/new"
import ControlPanelMobile from "../../components/ControlPanel/mobile"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CarouselMobile from "../../components/Carousel/mobile"
import FeatureSliderMobile from "../../components/FeatureSliderMobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import VideoDetailMobile from "../../components/VideoDetail/mobile"
import Configurador from "../../components/Configurador/"

// ASSETS
import RotoramVideo from "../../assets/videos/Rotoram/rotoram.mp4"
import RotoramVideoPreview from "../../assets/videos/Rotoram/rotoram-preview.mp4"
import RotoramDetailVideo from "../../assets/videos/Rotoram/rotoram-2.mp4"
import RotoramDetailVideo2 from "../../assets/videos/Rotoram/rotoram-3.mp4"
import RotoramDetailVideoMobile from "../../assets/videos/Rotoram/mobile/rotoram-video-detail-1.mp4"
import RotoramDetailVideoMobile2 from "../../assets/videos/Rotoram/mobile/rotoram-video-detail-2.mp4"

import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

const IndexPage = ({ location }) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language
  var config = true
  if(activeLanguage === 'br' || activeLanguage === 'mx' ){
    config = false
  }
  return (
    <Layout location={location} footer={t("footer", { returnObjects: true })} header={t("header", { returnObjects: true })}>
      <SEO
        pathname={location.pathname}
        title={t("meta", { returnObjects: true })["title"]}
        keywords={t("meta", { returnObjects: true })["keywords"]}
        description={t("meta", { returnObjects: true })["description"]}
        img={t("meta", { returnObjects: true })["image"]}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
        domain={location?.hostname}
      />
      <Video
        className="full-width full-height scrollable"
        srcp={RotoramVideo}
        src={RotoramVideoPreview}
        poster="https://ramalhos.com/video_posters/Rotoram/rotoram.jpg"
        width="100%"
        id="video-rotoram"
        imgMobile={t("video", { returnObjects: true })["bgMobile"]}
        fullString={t("videoHome", { returnObjects: true })["info"]}
        toClick="#introduction"
      />

      <Introduction
        className="scrollable"
        id="introduction"
        translation={t("introduction", { returnObjects: true })}
        lang={activeLanguage}
        link={"../../config-rotoram"}
        configurador
      />

      {!breakpoints.tl && ( // Desktop
        <>
          <Carousel
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-rotoram"
            carouselContent={t("carousel", { returnObjects: true })}
          />
          <VideoDetail
            src={RotoramDetailVideo}
            poster="https://ramalhos.com/video_posters/Rotoram/rotoram-2.jpg"
            id="video-detail-rotoram1"
            _title={t("video", { returnObjects: true })["title1"]}
            text1={t("video", { returnObjects: true })["text1_1"]}
            text2={t("video", { returnObjects: true })["text1_2"]}
          />
          <FeatureLine
            id="feature-line-image"
            _title={t("feature", { returnObjects: true })["title1"]}
            text={t("feature", { returnObjects: true })["text1"]}
            image={t("feature", { returnObjects: true })["image1_1"]}
            textWidth={"700px"}
            midBrightness
          />
          <VideoDetail
            src={RotoramDetailVideo2}
            poster="https://ramalhos.com/video_posters/Rotoram/rotoram-3.jpg"
            id="video-detail-rotoram2"
            _title={t("video", { returnObjects: true })["title2"]}
            text1={t("video", { returnObjects: true })["text2_1"]}
            text2={t("video", { returnObjects: true })["text2_2"]}
            positionCenter
          />
          <FeatureLine
            id="feature-line-image2"
            _title={t("feature", { returnObjects: true })["title2"]}
            text={t("feature", { returnObjects: true })["text2"]}
            titleWidth="800px"
            textWidth="500px"
          />
          <ControlPanel
            id="control-panel-rotoram"
            carouselContent={t("panel", { returnObjects: true })["panels"]}
            _title={{
              title1: t("panel", { returnObjects: true })["title1"],
              title2: t("panel", { returnObjects: true })["title2"],
            }}
            defaultImage={t("panel", { returnObjects: true })["panels"]["default"]}
          />
          <FeatureLine
            id="feature-line-image2"
            _title={t("feature", { returnObjects: true })["title3"]}
            image={t("feature", { returnObjects: true })["image3_1"]}
            marginLeft={"20%"}
          />
        </>
      )}

      {/* --- */}

      {breakpoints.tl && ( // Mobile
        <>
          <CarouselMobile
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-rotoram"
            carouselContent={t("carousel", { returnObjects: true })}
          />
          <FeatureLineMobile
            id="first-line-mobile"
            _title={t("feature", { returnObjects: true })["title1"]}
            text1={t("feature", { returnObjects: true })["text1"]}
            positionCenter
          />
          <FeatureSliderMobile
            id="second-line-mobile"
            image1={t("feature", { returnObjects: true })["image1_1M"]}
            image2={t("feature", { returnObjects: true })["image1_2M"]}
          />
          <VideoDetailMobile
            src={RotoramDetailVideoMobile}
            poster="https://ramalhos.com/video_posters/Rotoram/mobile/rotoram-video-detail-1.jpg"
            id="video-detail-rotoram-mobile"
            _title={t("video", { returnObjects: true })["title1"]}
            text={t("video", { returnObjects: true })["text1_1"]}
            positionAbsolute
          />
          <VideoDetailMobile
            src={RotoramDetailVideoMobile2}
            poster="https://ramalhos.com/video_posters/Rotoram/mobile/rotoram-video-detail-2.jpg"
            id="video-detail-rotoram-mobile-2"
            _title={t("video", { returnObjects: true })["title2"]}
            text={t("video", { returnObjects: true })["text2_1"]}
            text2={t("video", { returnObjects: true })["text2_2"]}
            positionAbsolute
            videoOpacity
          />
          <ControlPanelMobile
            id="control-panel-mobile-rotoram"
            _title={{
              title1: t("panel", { returnObjects: true })["title1"],
              title2: t("panel", { returnObjects: true })["title2"],
            }}
            carouselContent={t("panel", { returnObjects: true })["panels"]}
          />
        </>
      )}

      {/* --- */}

      {config && <Configurador id="rotoram-initial" content={t("configurador", { returnObjects: true })}/>}

      {/* <SpecsTable
        id="specs-table"
        specsTable={t("specs", { returnObjects: true })["content"]}
        specsLength={t("specs", { returnObjects: true })["content"].length}
        location={location}
        btnString="Contacte-nos"
        svgWidth="200px"
      /> */}

      {!(activeLanguage === "br") && (
        <FormContact id="form-contact" info={t("form", { returnObjects: true })} lang={activeLanguage} location={location} />
      )}
    </Layout>
  )
}

export default IndexPage

export const QueryRotoramPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["home", "rotoram", "form", "footer", "header"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
